import React from 'react'
import GenericDataRow from '../../../GenericPageElements/GenericDataRow'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'

export const DynamicColumnSet = ({children}) => {
 return (
   <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
     {children}
   </div>
 )
}

const CompanyInfoRight = ({ translations, data, mode = 'O', setMode, updateStatus, dates, selectedDate, workflow, setFileDate }) => {
  return (
    <div style={{gap: 10, display: 'flex', flexDirection: 'column'}}>
      <GenericDataRow title={translations['ov.select_alternative']} contentColour={'var(--white)'}>
        <select value={mode} onChange={_ => setMode(_.target.value)} style={{width: '100%', border: 'none'}}>
          <option value="O">Original</option>
          <option value="A">Alternative</option>
        </select>
      </GenericDataRow>
      <GenericDataRow title={translations['ov.status']} contentColour={'var(--white)'}>
        <select style={{width: '100%', border: 'none'}} onChange={(event) => updateStatus(event)} value={data['status'] ?? "New"}>
            <option value="New">New</option>
            <option value="Complex">Complex</option>
            <option value="In progress">In progress</option>
        </select>
      </GenericDataRow>
      <GenericDataRow title={translations['ov.account_balance']} contentColour={'var(--white)'}>{data['Account_Balance']}</GenericDataRow>
      <GenericDataRow title={translations['ov.landline']} contentColour={'var(--white)'}>{data['Landline']}</GenericDataRow>
      { 
        workflow ?
          <GenericDataRow title={"File Date"} contentColour={'var(--white)'}>
            <select style={{width: '100%', border: 'none'}} onChange={(event) => setFileDate(event.target.value)} value={selectedDate}>
              <option value=''>All Dates</option>
              {
                dates.map(date => {
                  return (
                    <option value={date.raw}>{date.pretty}</option>
                  )
                })
              }
            </select>
          </GenericDataRow>
        :
          <GenericDataRow title={translations['ov.director_match']} contentColour={'var(--white)'}>{data['Director_Match']}</GenericDataRow>
      }
    </div>
  )
}

const CompanyInfoLeft = ({ translations, data, mode = 'O' }) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10}}>
        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
          <GenericDataRow title={translations['ov.matched_name']} contentColour={'var(--white)'}>{
            data['O_Occupier_Score'] >= data['A_Occupier_Score'] ? data['O_Company_Name'] : data['A_Company_Name']
          }</GenericDataRow>
        </div>
        <GenericDataRow title={translations['ov.occupier_status']} contentColour={'var(--white)'}>{data['Occupier_Banding']}</GenericDataRow>
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10}}>
        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
          <GenericDataRow title={translations['ov.occupier_name']} contentColour={'var(--white)'}><span style={{fontWeight: mode === 'O' ? 'bold' : undefined}}>{data['O_Company_Name']}</span></GenericDataRow>
        </div>
        <GenericDataRow title={translations['ov.occupier_score']} contentColour={'var(--white)'}>{data['O_Occupier_Score']}</GenericDataRow>
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10}}>
        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
          <GenericDataRow title={translations['ov.alternative_occupier']}  contentColour={'var(--white)'}><span style={{fontWeight: mode === 'A' ? 'bold' : undefined}}>{data['A_Company_Name']}</span></GenericDataRow>
        </div>
        <GenericDataRow title={translations['ov.occupier_score']} contentColour={'var(--white)'}>{data['A_Occupier_Score']}</GenericDataRow>
      </div>
      <DynamicColumnSet>
        <GenericDataRow title={translations['ov.client_address']} contentColour={'var(--white)'}>{data['SPID_Address']}</GenericDataRow>
      </DynamicColumnSet>
      <DynamicColumnSet>
        <GenericDataRow title={translations['ov.matched_address']} contentColour={'var(--white)'}>{data['AddressBase_Address']}</GenericDataRow>
      </DynamicColumnSet>
    </div>
  )
}

export const CompanyInfo = ({ translations, data, mode = 'O', right, setMode, updateStatus, dates, selectedDate, workflow, setFileDate }) => {
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: 10}}>
          <GenericDataRow title={translations['ov.company_name']} contentColour={'var(--white)'}>{data['Customer_Name']}</GenericDataRow>
          <GenericDataRow title={translations['ov.company_number']} contentColour={'var(--white)'}>{data[mode+'_Company_Number']}</GenericDataRow>
          {right}
        </div>
        <div style={{display: 'grid', gridTemplateColumns: '3fr 1fr', gap: 10}}>
          <CompanyInfoLeft translations={translations} data={data} mode={mode} setMode={setMode} />
          <CompanyInfoRight translations={translations} data={data} mode={mode} setMode={setMode} updateStatus={updateStatus} dates={dates} selectedDate={selectedDate} workflow={workflow} setFileDate={setFileDate} />
        </div>
      </div>
    </>
  )
}

export const TitleOwnerRow = ({translations, data, mode = 'O'}) => {
  return (
    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10}}>
      <GenericDataRow title={translations['ov.cmos_voa_address']} contentColour={'var(--white)'} style={{ gridColumn: '2 span' }}>{data[mode+'_CMOS_UPRN_Single_Line']}</GenericDataRow>
      <GenericDataRow title={translations['ov.cmos_uprn_address']} contentColour={'var(--white)'} style={{ gridColumn: '2 span' }}>{data[mode+'_CMOS_VOA_Single_Line']}</GenericDataRow>
      <GenericDataRow title={translations['ov.title_number_owner']} contentColour={'var(--white)'}>{data[mode+'_LR_Name_No']}</GenericDataRow>
      <GenericDataRow title={translations['ov.title_owner_address']} contentColour={'var(--white)'}>{data[mode+'_LR_Address']}</GenericDataRow>
    </div>
  )
}

export const GoogleEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_Google_Company'],['Address', mode+'_Google_Address'],['Business Status',  mode+'_Google_Status'],['Phone', mode+'_Google_Phone'],['First Review Date', mode+'_Google_First_Date'],['Last Review Date', mode+'_Google_Date']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Google" align={'left'} rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const CompaniesHouseEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_Comp_Hse_Company'],['Address', mode+'_Comp_Hse_Address'],['Business Status', mode+'_Comp_Hse_Status'],['SIC', mode+'_Comp_Hse_SIC'],['Incorporated Date', mode+'_Comp_Hse_Inc_Date'],['Last Statement Date', mode+'_Comp_Hse_LS_Date']];
  return (
    <>
      <GenericTable firstColWidth={120} title="Companies House" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const YellEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_Yell_Company'],['Address', mode+'_Yell_Address'],['Business Status', mode+'_Yell_Status'],['Phone', mode+'_Yell_Phone'],['Website', mode+'_Yell_Website'],['Email', mode+'_Yell_Email']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Yell.Com" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const AddressDataEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_AD_Name'],['UPRN Address', mode+'_AddressBase_Address'],['UPRN', mode+'_UPRN'],['VOA Number', mode+'_AD_VOA_No'],['VOA End Date', mode+'_AD_VOA_End'],['CT Start Date', mode+'_AD_CT_Start']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Address Data" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const FSAEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_FSA_Name'],['Address', mode+'_FSA_Address'],['Rating Date', mode+'_FSA_Rating_Date']];
  return (
    <>
      <GenericTable firstColWidth={100} title="FSA" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const BTEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_BT_Name'],['Address', mode+'_BT_Address'],['Phone', mode+'_BT_Phone']];
  return (
    <>
      <GenericTable firstColWidth={100} title="BT Directory" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const TripAdvisorEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_CC_Name'],['Address', mode+'_CC_Address'],['Phone', mode+'_CC_Phone']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Trip Advisor" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const DVSAEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+`_DVSA_Name`],['Address', mode+`_DVSA_Address`],['Phone', mode+`_DVSA_Phone`]];
  return (
    <>
      <GenericTable firstColWidth={100} title="DVSA" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const CQCEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_CQC_Name'],['Address', mode+'_CQC_Address'],['Phone', mode+'_CQC_Phone']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Quality Care Comm" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const JustEatEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_JE_Company'],['Address', mode+'_JE_Address'],['Phone', mode+'_JE_Phone']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Just Eat" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const One92Evidence = ({data, mode = 'O'}) => {
  let tableFormat;
  if (mode == 'O') {
    tableFormat = [['Company', mode+'_One92_Company'],['Address', mode+'_One92_Address'],['Phone', mode+'_One92_Phone']];
  } else {
    tableFormat = [['Company', mode+'_Yell_Company'],['Address', mode+'_Yell_Address'],['Phone', mode+'_Yell_Phone']];
  }
  return (
    <>
      <GenericTable firstColWidth={100} title="192.com" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}

export const FacebookEvidence = ({data, mode = 'O'}) => {
  const tableFormat = [['Company', mode+'_FB_Company'],['Address', mode+'_FB_Address'],['Phone', mode+'_FB_Phone']];
  return (
    <>
      <GenericTable firstColWidth={100} title="Facebook" align="left" rows={tableFormat} data={data} extraClass="squish super-small-text extra-row-padding" />
    </>
  )
}