import React, { Component, useEffect } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './voidSalesAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import { CircularProgress } from '@mui/material'
import PageTitle from '../../../components/page-header/pageTitle'
import ArcgisMap from '../../../components/arcgis-map/arcgis'

export default view(
    withRouter(
        class VoidSalesAlertsSearch extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    translations: null,
                    loading: false,
                    showMapModal: false, 
                }
            }

            componentDidMount = () => {
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=vsa.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            getData = () => {
                this.setState({ loading: true });
                request(true).get(`${endpoints.VOID_SALES_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    // console.log(r.data.customer, r.data.customer ? '' : 'Customer Does Not Exist');
                    if (!r.data.customer) window.alert('Account not found.');
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null && r.data.customer.Deed_Status === 'Deed Available',
                        shouldRefreshMap: true,
                    }, () => {
                        this.setState({ loading: false });
                        this.setState({ shouldRefreshMap: false });
                    });
                }).catch(e => {
                    this.setState({ loading: false });
                    console.log(e);
                });
            }

            purchaseCustomer = () => {
                this.setState({ loading: true });
                request(true).post(`${endpoints.VOID_SALES_ALERTS_BUY_CUSTOMER}`, {
                    "customer": this.state.customer.Account_Number
                }).then(r => {
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer.Date_Bought === null
                    });
                    this.setState({ loading: false });
                }).catch(e => {
                    this.setState({ loading: false });
                    console.log(e);
                })
            }

            searchKeyDown = (e) => {
                if (e.which === 13) {
                    this.getData();
                }
            }

            componentWillUnmount() {
                useWSProduct('void_cot_alerts', 'unlisten');// eslint-disable-line
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Void Sale Alerts - Search" titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {
                                    this.state.loading ? <CircularProgress size={22} /> : null
                                    }
                                    <Link className="navigate" to="/portal/void-sales-alerts/">
                                        To Overview
                                    </Link>
                                    { (hasPermission('VSA', 'WORKFLOW')) ?
                                        <Link className="navigate" to="/portal/void-sales-alerts/workflow">
                                            To Workflow
                                        </Link>
                                    : null }
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="vsa">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row" style={{gridTemplateColumns: 'min-content auto min-content'}}>
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vsa.AccountNumber'] : '' }
                                            </div>
                                            <input disabled={this.state.loading} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } onChange={ this.accountNumberChange } onKeyPress={ this.searchKeyDown }/>
                                            <div style={{display: 'flex', alignItems: 'center', paddingLeft: '10px'}}>
                                                <span onClick={() => this.setState({showMapModal: true})} style={{textDecoration: 'none', color: 'var(--primary', cursor: 'pointer'}} class="fa-solid fa-map-location-dot"></span>                                                
                                            </div>
                                        </div>
                                        { this.state.customer && this.state.customer.Surname !== false ?
                                            this.state.customer ? this.state.customer.Deed_Surname ? <>
                                            <div className="name-grid">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vsa.title'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Title}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vsa.forename'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Forename}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vsa.surname'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Surname}` : '' }/>
                                                </div>
                                            </div>
                                            </> : <>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vsa.business'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Business}` : '' }/>
                                                </div>
                                            </> : null
                                        :
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vsa.Proprietor'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Title}` : '' }/>
                                            </div>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.ClientAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.SPID_Address : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.DeedAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Deed_Address : '' }/>
                                        </div>
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vsa.AddressScore'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Address_BRAG : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vsa.UPRN'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.UPRN : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vsa.BusinessMatch'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={this.state.customer? this.state.customer.Business_Flag : '' }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-grid">
                                        <button className="search-button" onClick={ this.getData }>
                                            Search
                                        </button>
                                        { (!hasPermission('VSA', 'PURCHASE') && this.state.purchasable) ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Purchase Deed
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable} onClick={ this.purchaseCustomer }>
                                                { this.state.purchasable === true || this.state.purchasable === null ? "Purchase Deed" :
                                                    this.state.customer.Bought_By ?
                                                        `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                    :
                                                        this.state.customer.Date_Bought ?
                                                            `Purchased on ${this.state.customer.Date_Bought}`
                                                        :
                                                            'Unavailable for Purchase'
                                                }
                                            </button>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.VoidAgeBand'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Voids_Age_Band : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.TransferDate'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Transfer_Date : ''}/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vsa.DeedType'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Deed_Type : '' }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="tertiary-grid">
                                    <div className="data-row">
                                        <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vsa.Bucket'] : '' }
                                        </div>
                                        <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Bucket : ''}/>
                                    </div>
                                    <div className="data-row">
                                        <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vsa.Freehold_Comp_Name'] : '' }
                                        </div>
                                        <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Freehold_Comp_Name  : '' }/>
                                    </div>
                                    <div className="data-row">
                                        <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vsa.Proprietor_Comp_Name'] : '' }
                                        </div>
                                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer? this.state.customer.Proprietor_Comp_Name : '' }/>
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null }/>
                                    <DeedPDFViewer customer={this.state.customer} refresh={this.getData} />
                                </div>
                                <ArcgisMap address={this.state.customer?.SPID_Address} isModal={this.state.showMapModal} closeModal={() => this.setState({showMapModal: false})} />
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

export function DeedPDFViewer({ customer, refresh }) {
    useEffect(() => {
        if (!customer) return;
        useWSProduct('void_sales_alerts', 'listen', `_${customer.Account_Number}`, () => {// eslint-disable-line
            refresh()
        })

        return () => {
            // cleanup
            useWSProduct('void_sales_alerts', 'unregister', `_${customer.Account_Number}`)// eslint-disable-line
        }
    })
    return (
        customer && customer.Bought_By ? customer.DeedPDF ? <div>
            <iframe title="Deed PDF" style={{minHeight: 400, minWidth: '100%'}} src={customer.DeedPDF} />
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>We're processing your deed purchase, it'll appear here automatically once available.</p>
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>Deed available after purchase.</p>
        </div>
    )
}