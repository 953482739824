import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import { useHistory } from 'react-router-dom';
import PropertyMap from '../../../components/property-map/property-map'
import ReactModal from 'react-modal'
import hasPermission from '../../../helpers/permissions'
import { MDS } from '../OccupierTelephoneValidation/sharedComponents'

export const Summary = ({workflow = false, translations = {}}) => {
  const voaDataRef = useRef(null);
  const history = useHistory();
  const [spid, setSpid] = useState()
  const [workflowItems, setWorkflowItems] = useState([])
  const [voaAddresses, setVoaAddresses] = useState([])
  const [addresses, setAddresses] = useState([])
  const [voaData, setVoaData] = useState();
  const [spidData, setSpidData] = useState();
  const [companyName, setCompanyName] = useState();
  const [landline, setLandline] = useState();
  const [mobile, setMobile] = useState();
  const [gapStatus, setGapStatus] = useState('');
  const [mdsReady, setMdsReady] = useState(true)
  const [mdsData, setMdsData] = useState([])
  const [customMDSPostcode, setCustomMDSPostcode] = useState('')
  const [submissionModal, setSubmissionModal] = useState(false);
  
  const [customInput, setCustomInput] = useState('');
  
  const getSummaryData = async (input) => {
    if (!spid) return
    setCompanyName(null)
    setLandline(null)
    setMobile(null)
    setMdsReady(false)
    request(true).get(`${endpoints.NON_HOUSEHOLD_GAPS_GET_GAP}?spid=${input || spid}`).then(r => {
      setSpidData(r.data)
      request(true).get(`${endpoints.NON_HOUSEHOLD_GAPS_GET_IDENTEQ_ADDRESSES_VOA}`, {
        params: {
          uprn: input || spid,
          postcode: r.data.CL_Postcode
        }
      }).then(e => {
        setVoaData(e.data)
        if (mdsData.length === 0 || e.data.Postcode !== mdsData[0].Premises_Address_Postcode) {
          setMdsData([])
          setCustomMDSPostcode('')
          getMDSData(e.data.Postcode)
        } else {
          setMdsReady(true)
        }
      }).catch(e => {
        window.alert('Could not find SPID.')
        setVoaAddresses([]);
        setAddresses([])
      })
    }).catch(e => {
      window.alert('Could not find SPID.')
      setVoaAddresses([]);
      setAddresses([])
    })
  }
  
  const getMDSData = (postcode) => {
    setMdsReady(false)
    request(true).get(`${endpoints.OCCUPIER_TELEPHONE_VALIDATION_MDS_SPID}?postcode=${postcode || voaData.Postcode}`).then(r => {
      setMdsReady(true)
      setMdsData(r.data)
    }).catch(e => {
      console.log(e)
      setMdsReady(true)
    })
  }

  useEffect(() => {
    if (workflow) {
      getWorkflowItems()
    }
  }, [])

  useEffect(() => {
    getSummaryData()
  }, [workflowItems])

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cse.google.com/cse.js?cx=93067ba5f6b904ade';
    script.async = true;

    script.onload = () => {
      const searchInput = document.querySelector('.gsc-input input');
      if (searchInput) {
        searchInput.value = voaData?.Full_Property_Identifier || '';
        const searchButton = document.querySelector('.gsc-search-button button');
        if (searchButton) {
          searchButton.click(); 
        }
      }
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [voaData]);

  useEffect(() => {
    const removeHash = () => {
      if (window.location.hash) {
        window.history.replaceState(null, document.title, window.location.pathname + window.location.search);
      }
    };

    removeHash();

    return () => {
      window.removeEventListener('hashchange', removeHash);
    };
  }, []);

  const getWorkflowItems = () => {
    request(true).get(`${endpoints.NON_HOUSEHOLD_GAPS_WF_ITEMS}?stage=4`).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        setSpid(e.data[0])
        getSummaryData(_cur && e.data[_cur-1] ? e.data[_cur-1] : e.data[0]);
      } else {
        setSpid()
        setVoaAddresses([]);
        setAddresses([])
      }
    })
  }

  const _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === spid);
  }, [workflowItems, spid]);

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length-1) {
      setSpid(workflowItems[_cur+1]);
      getSummaryData(workflowItems[_cur+1])
    } else {
      setSpid(workflowItems[0]);
      getSummaryData(workflowItems[0])
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      setSpid(workflowItems[_cur-1]);
      getSummaryData(workflowItems[_cur-1])
    } else {
      setSpid(workflowItems[workflowItems.length - 1]);
      getSummaryData(workflowItems[workflowItems.length - 1])
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getSummaryData()
    }
  }

  const submit = () => {
    request(true).post(endpoints.NON_HOUSEHOLD_GAPS_SUBMIT_GAP, {
      spid: spid,
      companyName: companyName ?? null,
      landline: landline ?? null,
      mobile: mobile ?? null,
      status: gapStatus
    }).then(r => {
      window.alert("Gap submitted successfully");
      if (workflow) {
        getWorkflowItems();
      } else {
        window.location.reload();
      }
    }).catch(e => {
      window.alert("Gap submission failed");
    })
  }

  useEffect(() => {
    if (gapStatus) {
      setSubmissionModal(true)
    } else {
      setSubmissionModal(false)
    }
  }, [gapStatus])

  useEffect(() => {
    if (voaData && voaData.Postcode) {
      request(true).get(endpoints.NON_HOUSEHOLD_GAPS_SEARCH_BY_POSTCODE, {
        params: {
          postcode: voaData.Postcode
        }
      }).then(r => {
        setAddresses(r.data);
      })
      request(true).get(endpoints.NON_HOUSEHOLD_GAPS_GET_VOAS, {
        params: {
          stage: '4',
          postcode: voaData.Postcode
        }
      }).then(r => {
        setVoaAddresses(r.data);
      })
    }
  }, [voaData])

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
      <div style={{display: 'grid', gap: 10}}>
        <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
          <col width={180} />
          <thead>
            <tr>
              <th colSpan={6}>{translations['NHG.Header_Summary']}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width={400}>{translations['NHG.CL_VOA_BA_Reference']}</td>
              <td colSpan={5}>
                <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                  <input style={{border: 'none'}} type="text" onChange={e => setSpid(e.target.value)} value={spid} onKeyDown={handleKeyDown} />
                    { workflow ? 
                      <>
                        <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                        <span>{_cur+1}/{workflowItems.length}</span>
                        <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                      </>
                    : null }
                    <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => getSummaryData()}></span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='NHG' style={{gridTemplateColumns: '1fr 1fr 1fr'}}>
        <table ref={voaDataRef} className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%', height: 'fit-content'}}>
        <thead>
            <tr>
            <td colSpan={2}>{translations['NHG.IDenteq_VOA']}</td>
            </tr>
          </thead>
          <tbody>
            <tr style={{width: 'fit-content'}}>
              <td>{translations['NHG.BA_Reference_Number']}</td>
              <td>{voaData?.BA_Reference_Number}</td>
            </tr>
            <tr>
              <td>{translations['NHG.Status_VOA_No']}</td>
              <td>{voaData?.ABP_Status}</td>
            </tr>
            <tr>
              <td>{translations['NHG.Source']}</td>
              <td>{voaData?.Source}</td>
            </tr>
            <tr>
              <td>{translations['NHG.Number_Or_Name']}</td>
              <td>{voaData?.Number_Or_Name}</td>
            </tr>
            <tr>
              <td>{translations['NHG.Street']}</td>
              <td>{voaData?.Street}</td>
            </tr>
            <tr>
              <td>{translations['NHG.Sub_Street_level_1']}</td>
              <td>{voaData?.Sub_Street_level_1}</td>
            </tr>
            <tr>
              <td>{translations['NHG.Town']}</td>
              <td>{voaData?.Town}</td>
            </tr>
            <tr>
              <td>{translations['NHG.Postal_District']}</td>
              <td>{voaData?.Postal_District}</td>
            </tr>
            <tr>
              <td>{translations['NHG.County']}</td>
              <td>{voaData?.County}</td>
            </tr>
            <tr>
              <td>{translations['NHG.Postcode']}</td>
              <td>{voaData?.Postcode}</td>
            </tr>
          </tbody>
        </table>

        <div style={{overflow: 'auto', height: voaDataRef?.current?.clientHeight}}>
            <div style={{overflow: 'auto'}} className='gcse-searchbox' data-autoSearchOnLoad="false" data-autosearchonload="false" data-autoCompleteMaxCompletions="1"></div>
            <div className='gcse-searchresults' data-autoSearchOnLoad="false" data-autosearchonload="false"></div>
        </div>

        <div style={{height: 'auto'}}>
              <PropertyMap address={ voaData ? voaData.Full_Property_Identifier : null } />
        </div>
      </div>
      <div style={{overflowY: 'auto', maxHeight: '70vh'}}>
        <table className='table borders squish smaller-text left' style={{tableLayout: 'auto', width: '100%', height: 'fit-content'}}>
            <thead style={{position: 'sticky', top: '0px'}}>
                <tr>
                    <td>{translations['NHG.VOA_Address']}</td>
                    <td>{translations['NHG.UPRN_ADDRESS']}</td>
                    <td>{translations['NHG.SPID_ADDRESS']}</td>
                    <td>{translations['NHG.CMOS_NAME']}</td>
                    <td>{translations['NHG.OCCUPANCY_TYPE']}</td>
                    <td>{translations['NHG.VACA_NAME']}</td>
                    <td>{translations['NHG.OCCUPIER_SCORE']}</td>
                </tr>
            </thead>
            <tbody>
                { addresses && addresses.length ?
                    addresses
                    .sort((a, b) => {
                      // First, sort by Category
                      if (a.Category === 'Potential Gap' && b.Category !== 'Potential Gap') {
                        return -1;
                      } else if (a.Category !== 'Potential Gap' && b.Category === 'Potential Gap') {
                        return 1; 
                      } else {
                        const scoreOrder = { 'DQI': 0, 0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6 };
                        const scoreComparison = scoreOrder[b.Occupier_Score] - scoreOrder[a.Occupier_Score];
                        return scoreComparison;
                      }
                    })
                    .map(item => {
                        return (
                            <tr>
                                <td>{voaAddresses.length ? voaAddresses.find(voa => voa.BA_Reference_Number === item.SPID)?.Full_Property_Identifier : ''}</td>
                                <td>{item.AddressBase_Address}</td>
                                <td>{item.CMOS_Address}</td>
                                <td>{item.MDS_Name}</td>
                                <td>{item.Occupancy_Type}</td>
                                <td>{item.Company_Name}</td>
                                <td style={{textAlign: 'center'}}>{item.Occupier_Score}</td>
                            </tr>
                        )
                    })
                : 
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                }
            </tbody>
        </table>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
        <button onClick={() => { setGapStatus('Bulk Meter'); setSubmissionModal(true) }} className='button compact cancel smaller-text'>Reject Bulk Meter</button>
        <button onClick={() => { setGapStatus('Rejected'); setSubmissionModal(true) }} className='button compact cancel smaller-text'>Reject Gap</button>
        <button onClick={() => { setGapStatus('Accepted'); setSubmissionModal(true) }} className='button compact background-primary colour-white smaller-text'>Accept Gap</button>
      </div>
      <div>
        <MDS data={mdsData} getData={getMDSData} MDSDataReady={mdsReady} customPostcode={customMDSPostcode} setCustomPostcode={setCustomMDSPostcode} classes={'squish smaller-text left'} style={{tableLayout: 'auto', width: '100%', height: 'fit-content'}} />
      </div>
      <ReactModal 
      isOpen={submissionModal} 
      onRequestClose={() => setSubmissionModal(false)}
      className="card bulk-allocate"
      contentLabel="Wholesaler GAPs gap submission"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Please confirm that you wish to {gapStatus === "Accepted" ? "accept" : "reject"} this gap {gapStatus === "Bulk Meter" ? 'and all others in its postcode' : ''}.</h3>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <div className="data-row">
              <div className="data-row-title">
                  { translations['NHG.CL_VOA_BA_Reference']  }
              </div>
              <input type="text" className="data-row-field" disabled={true} value={spid ??''}/>
            </div>
            <div className="data-row">
              <div className="data-row-title">
                  { translations['NHG.CLIENT_UPRN']  }
              </div>
              <input type="text" className="data-row-field" disabled={true} value={spidData?.Client_UPRN ? spidData?.Client_UPRN : ''}/>
            </div>
            <div className="data-row">
              <div className="data-row-title">
                  { translations['NHG.VACA_NAME']  }
              </div>
              <input 
                type="text" 
                className="data-row-field" 
                onChange={(event) => setCompanyName(event.target.value)} 
                disabled={hasPermission('NON_HOUSEHOLD_GAPS', 'PURCHASE') ? gapStatus === "Accepted" ? false : true : true} 
                value={companyName ? companyName : spidData?.Company_Name ? spidData?.Company_Name : '' }/>
            </div>
            <div className="data-row">
              <div className="data-row-title">
                  Landline
              </div>
              <input 
                type="text" 
                className="data-row-field" 
                onChange={(event) => setLandline(event.target.value)} 
                disabled={hasPermission('NON_HOUSEHOLD_GAPS', 'PURCHASE') ? gapStatus === "Accepted" ? false : true : true} 
                value={landline ? landline : spidData?.Landline ? spidData?.Landline : '' }/>
            </div>
            <div className="data-row">
              <div className="data-row-title">
                  Mobile
              </div>
              <input 
                type="text" 
                className="data-row-field" 
                onChange={(event) => setMobile(event.target.value)} 
                disabled={hasPermission('NON_HOUSEHOLD_GAPS', 'PURCHASE') ? gapStatus === "Accepted" ? false : true : true} 
                value={mobile ? mobile : spidData?.Mobile ? spidData?.Mobile : '' }/>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setSubmissionModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => { setSubmissionModal(false); submit(); }}
            >{gapStatus === "Accepted" ? "Accept" : "Reject"}</button> 
          </div>
        </div>
      </ReactModal>
    </div>
  )
}
