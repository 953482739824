import React from 'react';
import Modal from 'react-modal';
import endpoints from '../../../helpers/endpoints';
import request from '../../../helpers/request';
import '../../../components/cot-alert-bulk-buy/bulkBuy.scss'

export default function BatchRunModal ({
    modalIsOpen, closeModal, postcode,
    ...props
}) {

    const triggerBatchProcess = () => {
        request(true).post(endpoints.NON_HOUSEHOLD_GAPS_TRIGGER_BATCH, {
            postcode: postcode,
            stage: 'S3'
        }).then(r => {
            window.alert(r.data)
            window.location.reload();
        }).catch(e => {
            console.log(e);
            window.alert('Batch run failed')
        });
    }

    return (
        <Modal
            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
            className="card bulk-buy"
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Batch Run Trigger"
            appElement={document.getElementById('root')}
        >
            <div className='bbmodal-text'>
                {
                    postcode ?
                        <p>
                            Click 'confirm' to trigger the <strong>BAA</strong>, <strong>VaCA</strong> and <strong>Exclusion Processing</strong> batch process for the <strong>{postcode.toUpperCase()}</strong> region.
                        </p>
                    :
                        <p>
                            Please ensure you have a <strong>postcode</strong> selected first. You can select one using the <strong>'Input Postcode'</strong> button.
                        </p>
                }
            </div>
            <div className="grid-2">
                <button className="button cancel" onClick={ closeModal }>Cancel</button>
                <button className="button confirm" onClick={ () => triggerBatchProcess() }>
                    Confirm
                </button>
            </div>
        </Modal>
    )
}