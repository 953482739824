import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './vacantCOTAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'

// import {DeedPDFViewer} from './voidSalesAlertsSearch'
import {GoogleEvidence,AddressEvidence,YellComEvidence,CompaniesHouseEvidence,RightColumn, LeftColumn, FoodStandardsEvidence, BTDirectoryEvidence, CharityCommisionEvidence, DVSAEvidence, CQCEvidence, JustEatEvidence, One92Evidence, FacebookEvidence, TitleOwnerRow} from './sharedComponents'
import ProductIcons from '../../../helpers/productIcons'
import ReactModal from 'react-modal'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class VacantCOTAlertsWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    message: '',
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    workflow: [],
                    workflowIndex: null,
                    workflowLength: 0,
                    disableActions: false,
                    translations: {},
                    exclusionReason: 'null',
                    workflowModalIsOpen: 'null',
                    selectedWholesaler: null,
                    wholesalers: [],
                    selectedAdcGroup: '',
                    adcGroups: [],
                    selectedDate: '',
                    dates: [],
                }
            }

            componentDidMount() {
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=vacant_cot_alerts.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
              
                const {history} = this.props;
              
                var index = history.location?.state?.index;
                var wholesaler = history.location?.state?.wholesaler;
                var adcGroup = history.location?.state?.adcGroup;
                var date = history.location?.state?.date

                request(true).get(endpoints.VACANT_COT_ALERTS_GET_WORKFLOW_DATES).then(r => {
                    this.setState({
                        dates: r.data.dates,
                    }, () => {
                        request(true).get(`${endpoints.VACANT_COT_ALERTS_DASHBOARD_WHOLESALER}?user=${session.user.id}`).then(r => {
                            this.setState({
                                wholesalers: r.data, 
                            }, () => {
                                this.getWorkflow(index);
                            });
                        });
                    });
                });
                
            }

            componentWillUnmount() {
                useWSProduct('void_sales_alerts', 'unlisten');// eslint-disable-line
            }

            getWorkflow = (index) => {
                request(true).get(endpoints.VACANT_COT_ALERTS_WORKFLOW, {
                    params: {
                        selectedWholesaler: this.state.selectedWholesaler,
                        selectedAdcGroup: this.state.selectedAdcGroup,
                        selectedDate: this.state.selectedDate,
                    }
                }).then(r => {
                    if (index > r.data.length - 1) {
                        index --;
                    }
                    this.setState({
                        workflow: r.data,
                        workflowLength: r.data.length,
                        accountNumber: index ? r.data[index] : r.data[0],
                        workflowIndex: index ? index : 0,
                        workflowModalIsOpen: 'null',
                    }, () => {
                        const {history} = this.props
                        history.replace() 
                        this.getData();
                    });
                }).catch(e => {
                    console.error(e);
                });
            }

            getData = () => {
                this.setState({customer: null, purchasable: null})
                request(true).get(`${endpoints.VACANT_COT_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customer);
                    this.setState({
                        customer: r.data.customer,
                        message: r.data.customer ? '' : 'Customer Does Not Exist',
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null,
                        shouldRefreshMap: true,
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            disableActions: false,
                        });
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = (occupierPurchaseType) => {
                this.setState({disableActions: true}, () => {
                    request(true).post(`${endpoints.VACANT_COT_ALERTS_BUY_CUSTOMER}`, {
                        "customer": this.state.customer.SPID,
                        "occupier_purchase_type": occupierPurchaseType,
                    }).then(res => {
                        this.getWorkflow()
                    }).catch(e => {
                        console.log(e);
                    })
                });
            }

            openWorkflowBuyModal = () => {
                this.setState({
                    workflowModalIsOpen: 'true'
                })
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.SPID) return;
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.VACANT_COT_ALERTS_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.SPID,
                        Occupier_Exclusion_Reason: this.state.exclusionReason
                    }).then(r => {
                        var customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowLength: customers.length,
                            accountNumber: customers[this.state.workflowIndex],
                            exclusionReason: 'null'
                        }, () => {
                            this.getData();
                        });
                    }).catch(e => {
                        console.error(e);
                    });
                });
            }

            workflowNext = () => {
                if (this.state.workflowIndex + 1 > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex + 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex + 1]
                }, () => {
                    this.getData();
                });
            }

            workflowPrev = () => {
                if (this.state.workflowIndex > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex - 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex - 1]
                }, () => {
                    this.getData();
                });
            }

            updateStatus = (event) => {
                this.setState({
                    customer: {
                        ...this.state.customer,
                        status: event.target.value
                    }
                }, () => {
                    request(true).post(`${endpoints.VACANT_COT_ALERTS_UPDATE_STATUS}`, {
                        customer: this.state.customer.SPID,
                        status: this.state.customer.status
                    }).catch(e => {
                        console.log(e);
                    })
                })
            }

            setAdcGroup = (event) => {
                this.setState({selectedAdcGroup: event.target.value, customer: null, accountNumber: ''}, () => 
                    this.getWorkflow()
                );
            }

            setWholesaler = (event) => {
                this.setState({selectedWholesaler: event.target.value}, () => 
                    this.getWorkflow()
                );
            }

            setFileDate = (event) => {
                this.setState({selectedDate: event.target.value}, () => 
                    this.getWorkflow()
                );
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Vacant COT Alerts - Workflow" iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to="/portal/vacant-cot-alerts/">
                                        To Overview
                                    </Link>
                                    <Link className="navigate" to="/portal/vacant-cot-alerts/search">
                                        To Search
                                    </Link>
                                    { (hasPermission('VACANT_COT', 'DASHBOARD')) ?
                                        <Link className="navigate" to="/portal/vacant-cot-alerts/dashboard">
                                            To Dashboard
                                        </Link>
                                    : null }
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="vacantCOTAlerts">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row with-buttons">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vacant-cot-alerts.spid'] : '' }
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <input disabled={true} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
                                                <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                                <button className="button background-primary colour-white" onClick={ this.workflowPrev } disabled={this.state.workflowIndex === 0}>
                                                    <i className="fas fa-angle-left"></i>
                                                </button>
                                                <button className="button background-primary colour-white" onClick={ this.workflowNext } disabled={this.state.workflowIndex === this.state.workflowLength - 1}>
                                                    <i className="fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <LeftColumn state={this.state} />
                                    </div>
                                    <RightColumn state={this.state} setAdcGroup={this.setAdcGroup} setWholesaler={this.setWholesaler} setFileDate={this.setFileDate} >
                                    { (!hasPermission('VACANT_COT', 'PURCHASE') || !this.state.purchasable) ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Occupier Moved In
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable || this.state.disableActions } onClick={ this.openWorkflowBuyModal }>
                                                {
                                                    this.state.customer && this.state.customer.Purchase_Type === 'VaCA_ACCOUNT_PURCHASED' ? <>
                                                        {
                                                            this.state.customer.Bought_By ?
                                                                `Moved in on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                            :
                                                                this.state.customer.Date_Bought ?
                                                                    `Move in on ${this.state.customer.Date_Bought}` : ''
                                                        }
                                                    </> : this.state.customer && this.state.customer.Purchase_Type === 'VaCA_ACCOUNT_PURCHASED' ? <>
                                                        Excluded
                                                    </> : <>
                                                        {
                                                            this.state.purchasable === true || this.state.purchasable === null ? "Occupier Moved In" : 'Unavailable for Move in'
                                                        }
                                                    </>
                                                }
                                                
                                            </button>
                                        }
                                        {/* <button className="buy-button" onClick={this.excludeCustomer} disabled={ this.state.disableActions }>
                                            Occupier Excluded
                                        </button> */}
                                        <select style={{height: 28, marginTop: 7, border: '1px solid var(--primary)', borderRadius: 5}} onChange={_ => this.setState({exclusionReason: _.target.value})} value={this.state.exclusionReason}>
                                            <option value={'null'}>Select an exclusion reason</option>
                                            <option>Existing Live Account</option>
                                            <option>Multiple Occupier/LL Query</option>
                                            <option>Address/Data Quality Issue</option>
                                            <option>Previous Occupier Provided</option>
                                            <option>Adjacent Occupier Provided</option>
                                            <option>De-registration</option>
                                            <option>Account Already Open - Same Occupier</option>
                                            <option>Account Already Open - Different Occupier</option>
                                            <option>Other Retailer</option>
                                            <option>Other</option>
                                        </select>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                Status
                                            </div>
                                            <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={this.updateStatus} value={this.state.customer?.status ?? "New"}>
                                                <option value="New">New</option>
                                                <option value="Complex">Complex</option>
                                                <option value="In progress">In progress</option>
                                            </select>
                                        </div>
                                    </RightColumn>
                                    <TitleOwnerRow state={this.state} />

                                </div>
                                <div className="secondary-grid">
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%', marginBottom: 5}}>
                                        <GoogleEvidence data={this.state.customer} />
                                        <CompaniesHouseEvidence data={this.state.customer} />
                                        <YellComEvidence data={this.state.customer} />
                                        <AddressEvidence data={this.state.customer} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                        <FoodStandardsEvidence data={this.state.customer} />
                                        <BTDirectoryEvidence data={this.state.customer} />
                                        <CharityCommisionEvidence data={this.state.customer} />
                                        <DVSAEvidence data={this.state.customer} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                        <CQCEvidence data={this.state.customer} />
                                        <JustEatEvidence data={this.state.customer} />
                                        <One92Evidence data={this.state.customer} />
                                        <FacebookEvidence data={this.state.customer} />
                                    </div>
                                </div>
                                <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px' }}>
                                    <table className="table borders squish super-small-text extra-row-padding">
                                        <thead>
                                            <tr>
                                                <th style={{textAlign: 'left'}}>
                                                    Days
                                                </th>
                                                <th>
                                                    Opening Times
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                                                    return (
                                                        <tr>
                                                            <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                                                            <td style={{minWidth: "unset"}}>{ this.state.customer?.Google_Opening_Hours ? JSON.parse(this.state.customer.Google_Opening_Hours.replaceAll('\'', '"'))[day] : null }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{height: 'auto'}}>
                                    { this.state.showMap ?
                                        <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    :
                                        <>
                                            <div className="map hidden" onClick={ () => { if (this.state.customer) { this.setState({ showMap: true })}} }>
                                                { this.state.customer ? 'Click to show map' : '' }
                                            </div>
                                        </>
                                    }
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vacant_workflow_video_url}/>
                        <ReactModal 
                        className="card bulk-buy"
                        isOpen={this.state.exclusionReason !== 'null'}>
                            <p>You have selected the following exclusion reason</p>
                            <p>{this.state.exclusionReason}</p>
                            <div className='grid grid-columns-2 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({exclusionReason: 'null'})}>Cancel</button>
                                <button className='button compact background-primary colour-white' onClick={() => this.excludeCustomer()}>Confirm</button>
                            </div>
                        </ReactModal>
                        <ReactModal
                        className="card bulk-buy"
                        isOpen={this.state.workflowModalIsOpen !== 'null'}>
                            <p>You have selected to move in an occupier </p>
                            <p>Select either Cancel, IDenteq for identified name or Other for an other name </p>

                            <div className='grid grid-columns-3 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({workflowModalIsOpen: 'null'})}>Cancel</button>
                                { session.company.vaca_confirmation_screen === 1 ?
                                    <button
                                        className='button compact background-primary colour-white'
                                        onClick={() => {
                                            console.log('Source Component - onClick', this.state.customer?.SPID, this.props.location.history);

                                            this.props.history.push({
                                                pathname: `/portal/vacant-cot-alerts/confirmation/`, 
                                                state: { spid: this.state.customer?.SPID, index: this.state.workflowIndex, wholesaler: this.state.selectedWholesaler, adcGroup: this.state.selectedAdcGroup, date: this.state.selectedDate, prevPath: this.props.history.location.pathname }
                                            })}
                                        }
                                    >
                                    Identeq
                                    </button>
                                :
                                    <button className='button compact background-primary colour-white' onClick={() => this.purchaseCustomer('IDenteq')}>IDenteq</button>
                                }
                                <button className='button compact background-primary colour-white' onClick={() => this.purchaseCustomer('Other')}>Other</button>
                            </div>
                        </ReactModal>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

// function Map({ shouldRefreshMap, ...props }) {
//     const [center, setCenter] = React.useState({});
//     const [loaded, setLoaded] = React.useState(false);
//     const [zoom, setZoom] = React.useState(0);
//     const [refreshMap, setRefreshMap] = React.useState(false);
//     const [showMap, setShowMap] = React.useState(true);
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
//     });

//     const onLoad = React.useCallback(map => {
//         setLoaded(true);
//         //console.log(process.env.REACT_APP_MAPS_API_KEY);
//     }, []);

//     useEffect(() => {
//         if (loaded) {
//             const geocoder = new window.google.maps.Geocoder();

//             geocoder.geocode({address: props.address}, (res, status) => {
//                 if (status === "OK") {
//                     setCenter(res[0].geometry.location);
//                     setZoom(18);
//                 }
//             });
//         }
//     }, [props.address, loaded])

//     useEffect(() => {
//         if (shouldRefreshMap !== refreshMap) {
//             setRefreshMap(shouldRefreshMap);
//             setShowMap(!shouldRefreshMap);
//         }
//     }, [shouldRefreshMap, refreshMap]);

//     return (
//         showMap && isLoaded && !loadError ? <>
//             <GoogleMap
//                 mapContainerClassName="map"
//                 onLoad={ onLoad }
//                 zoom={ zoom }
//                 center={ center }
//             >
//                 <Marker position={ center }/>
//             </GoogleMap>
//         </> : loadError ? <div>Error loading map.</div> : null
//     )
// }