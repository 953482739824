import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import endpoints from '../../../helpers/endpoints';
import request from '../../../helpers/request';
import {
  CompanyInfo,
  GoogleEvidence,
  CompaniesHouseEvidence,
  YellEvidence,
  AddressDataEvidence,
  FSAEvidence,
  BTEvidence,
  TripAdvisorEvidence,
  DVSAEvidence,
  CQCEvidence, 
  JustEatEvidence, 
  One92Evidence, 
  FacebookEvidence,
  TitleOwnerRow
} from './shared'
import Select from 'react-select'
import GenericDataRow, { GenericDataRowTitle } from '../../../GenericPageElements/GenericDataRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import PropertyMap from '../../../components/property-map/property-map'
import Twilio from '../../../components/twilio/twilio';
import ReactModal from 'react-modal';
import hasPermission from '../../../helpers/permissions';

export default function IdenteqOccupierValidationWorkflow() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [workflowItems, setWorkflowItems] = useState([])
  const [selectedSPID, setSelectedSPID] = useState('');
  const [mode, setMode] = useState('O')
  const [translations, setTranslations] = useState({})
  const [customerNumber, setCustomerNumber] = useState()
  const [twilioHeight, setTwilioHeight] = useState('500')
  const [exclusionReason, setExclusionReason] = useState('null')
  const [moveinType, setMoveinType] = useState('null')
  useEffect(() => {
    const div = document.getElementById('ov-workflow');
    setTwilioHeight(div ? div.clientHeight : null);
    request(true).get('/translate?key=ov.')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  const currentWorkflowPosition = useMemo(() => {
    if (!selectedSPID) return 0
    return workflowItems.indexOf(selectedSPID) + 1
  }, [workflowItems, selectedSPID])
  const getData = () => {
    setCustomerNumber(null);
    request(true).get(endpoints.IDENTEQ_OCCUPIED_VALIDATION_LOOKUP, {
      params: {
        SPID: selectedSPID

      }
    }).then(e => {
      setData(e.data)
    })
  }
  const getWorkflowItems = () => {
    setWorkflowItems([])
    setData({})
    setSelectedSPID('')
    request(true).get(endpoints.IDENTEQ_OCCUPIER_VALIDATION_WF_ITEMS).then(e => {
      setWorkflowItems(e.data)
      console.log(e.data.length)
      if (e.data.length > 0) setSelectedSPID(e.data[0])
    })
  }
  useEffect(() => {
    getWorkflowItems()
  }, [])
  useEffect(() => {
    getData()
  }, [selectedSPID])
  useEffect(() => {
    request(true).post(`${endpoints.IDENTEQ_OCCUPIER_VALIDATION_UPDATE_STATUS}`, {
      customer: data['SPID'],
      status: data['status']
    }).catch(e => {
      console.log(e);
    })
  }, [data.status])
  const wfDir = (dir = 'prev') => {
    if (dir === 'prev') {
      if (currentWorkflowPosition !== 1) setSelectedSPID(workflowItems[currentWorkflowPosition - 2])
    } else if (currentWorkflowPosition !== workflowItems.length) setSelectedSPID(workflowItems[currentWorkflowPosition])
  }
  const purchase = () => {
    request(true).post(endpoints.IDENTEQ_OCCUPIER_VALIDATION_PURCHASE, {
      spid: selectedSPID,
      Occupier_Purchase_Type: moveinType
    }).then(e => {
      getWorkflowItems()
      setMoveinType('null')
    }).catch(() => {
      console.log('Unable to purchase')
    })
  }
  const exclude = () => {
    request(true).post(endpoints.IDENTEQ_OCCUPIER_VALIDATION_EXCLUDE, {
      spid: selectedSPID,
      Occupier_Exclusion_Reason: exclusionReason
    }).then(e => {
      getWorkflowItems()
      setExclusionReason('null')
    }).catch(() => {
      console.log('Unable to exclude')
    })
  }
  const updateStatus = (event) => {
    setData({
      ...data,
      status: event
    })
  }
  const updateCustomerNumber = (number) => {
    setCustomerNumber(number)
  }
  const updateCustomer = (customer) => {
    setData(customer)
  }
  return (
    <GenericPageContainer containerWidth={'97.5vw'} title="Identeq Occupier Validation - Workflow"
    titleRight={<div>
        {
          loading ? <CircularProgress size={22} /> : null
        }
        <Link className="navigate" to="/portal/identeq-occupier-validation">
            To Overview
        </Link>
        <Link className="navigate" to="/portal/identeq-occupier-validation/search">
            To Search
        </Link>
      </div>}>
      <div style={{display:'grid', gridTemplateColumns:'4fr 1fr', gridGap:'15px', gap:'15px'}}>
        <div id="ov-workflow">
          <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <div style={{display: 'grid', gridTemplateColumns: '3fr 1fr', gap: 10}}>
              <div style={{display: 'grid', gridTemplateColumns: 'auto max-content', gap: 10}}>
                <GenericDataRow title={translations['ov.spid']}contentColour={'var(--white)'}>
                  <input value={selectedSPID} disabled={true} style={{width: '100%', outline: 'none', border: 'none', backgroundColor: 'var(--white)'}} />
                </GenericDataRow>
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center'}}>
                  <div onClick={() => wfDir('prev')}><i className="colour-primary fas fa-angle-left"></i></div>
                  <span style={{width: 40, textAlign: 'center'}}>{currentWorkflowPosition}/{workflowItems.length}</span>
                  <div onClick={() => wfDir('next')}><i className="colour-primary fas fa-angle-right"></i></div>
                </div>
              </div>
              { !hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'PURCHASE') ?
                <select disabled style={{ height: 28, marginTop: 7, borderRadius: 5, fontWeight: 'bold' }} className='disabled'>
                  <option value={'null'}>Occupier Moved In</option>
                </select>
                :
                <select style={{ height: 28, marginTop: 7, borderRadius: 5, backgroundColor: 'var(--secondary)', color: 'white', fontWeight: 'bold' }} onChange={_ => setMoveinType(_.target.value)} value={moveinType}>
                  <option value={'null'}>Occupier Moved In</option>
                  <option value={'Client Occupier Moved In'}>Client Occupier Moved In</option>
                  <option value={'Identeq Occupier Moved In'}>Identeq Occupier Moved In</option>
                  <option value={'Other Occupier Moved In'}>Other Occupier Moved In</option>
                </select>
              }
            </div>
            <CompanyInfo translations={translations} mode={mode} setMode={setMode} updateStatus={updateStatus} data={data} right={<>
              <select style={{ height: 28, marginTop: 7, borderRadius: 5, backgroundColor: 'var(--secondary)', color: 'white', fontWeight: 'bold' }} onChange={_ => setExclusionReason(_.target.value)} value={exclusionReason}>
                <option value={'null'}>Excluded</option>
                <option value={'No Answer'}>No Answer</option>
                <option value={'Refusal to confirm details'}>Refusal to confirm details</option>
                <option value={'No Telephone Number'}>No Telephone Number</option>
                <option value={'Potentially Vacant'}>Potentially Vacant</option>
                <option value={'Address DQI'}>Address DQI</option>
                <option value={'Previous Occupier'}>Previous Occupier</option>
                <option value={'Adjacent Occupier'}>Adjacent Occupier</option>
                <option value={'Other'}>Other</option>
              </select>
            </>} />
            <TitleOwnerRow translations={translations} data={data} mode={mode} />
            <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
              <GoogleEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <CompaniesHouseEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <YellEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <AddressDataEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
            <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
              <FSAEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <BTEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <TripAdvisorEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <DVSAEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
              <CQCEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <JustEatEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <One92Evidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <FacebookEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
          </div>

          <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px', display: 'grid', minHeight: '300px'}}>
              <table className="table borders squish super-small-text extra-row-padding">
                  <thead>
                      <tr>
                          <th style={{textAlign: 'left'}}>
                              Days
                          </th>
                          <th>
                              Opening Times
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                  {
                      ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                          return (
                              <tr>
                                  <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                                  <td style={{minWidth: "unset"}}>{ data?.[mode + "_Google_Opening_Hours"] ? JSON.parse(data?.[mode + "_Google_Opening_Hours"].replaceAll('\'', '"'))[day] : null }</td>
                              </tr>
                          )
                      })
                  }
              </tbody>
              </table>
              <div style={{height: 'auto'}}>
                  <PropertyMap address={ data ? data.SPID_Address : null } />
              </div>
          </div>
        </div>
        <Twilio module="identeq_occupier_validation" accountNumber={selectedSPID} customer={data} updateCustomer={updateCustomer} customerNumber={customerNumber} updateCustomerNumber={updateCustomerNumber} height={twilioHeight} surveyEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_SUBMIT_ANSWERS} />
      </div>
      <ReactModal
        className="card bulk-buy"
        isOpen={moveinType !== 'null'}>
        <p>You have selected the following move in type</p>
        <p>{moveinType}</p>
        <div className='grid grid-columns-2 grid-gap-5'>
          <button className='button compact background-red colour-white' onClick={() => setMoveinType('null')}>Cancel</button>
          <button className='button compact background-primary colour-white' onClick={() => purchase()}>Confirm</button>
        </div>
      </ReactModal>
      <ReactModal
        className="card bulk-buy"
        isOpen={exclusionReason !== 'null'}>
        <p>You have selected the following exclusion reason</p>
        <p>{exclusionReason}</p>
        <div className='grid grid-columns-2 grid-gap-5'>
          <button className='button compact background-red colour-white' onClick={() => setExclusionReason('null')}>Cancel</button>
          <button className='button compact background-primary colour-white' onClick={() => exclude()}>Confirm</button>
        </div>
      </ReactModal>
    </GenericPageContainer>
  )
}
